<template>
    <section class="assetsWarehousing">
        <div class="top-bar bg-white" style="justify-content: left">
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">资产名称:</span>
                <el-input size="small" style="width: 200px;" v-model="formSearch.assetsName" placeholder="请输入资产名称"/>
            </div>
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">资产状态:</span>
                <el-select size="small" v-model="formSearch.assetsState" style="width: 200px;" placeholder="请选择资产状态">
                    <el-option label="全部" :value="0"/>
                    <el-option v-for="(item,index) in assetsStateList" :key="index" :label="item.codeName"
                               :value="item.code"/>
                </el-select>
            </div>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <!--<el-button type="primary" size="small" @click="handleContractExport">导出数据</el-button>-->
          <el-button type="primary" size="small"  @click="batchGetApiClick">下载导入模板</el-button>
            <el-button type="primary" size="small" icon="el-icon-plus" @click="openAdd">新建入库</el-button>
            <el-button type="primary" size="small" @click="handleImport">导入</el-button>
            <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="goBack">返回</el-button>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentTableRef" :dataRequest="getParentListPage" :columns="tableColumn"
                       :query="formSearch" :height="800" show-summary :summary-method="getSummaries" notAuto>
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="200px">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="editData(row)">修改</span>
                            <span class="table-btn" @click="lookDetail(row)">详情</span>
                            <!-- <span class="table-btn" style="color: #FF3232;" @click="reomveAsset(row)">删除</span> -->
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <dialog-add-warehousing ref="dialogAddWarehousing" @handleSearch="handleSearch"/>

        <layer-assets-import ref="layerAssetsImport" @click-cancel="handleSearch"/>

        <layer-edit-assets-warehousing ref="layerEditAssetsWarehousing" @handleSearch="handleSearch" />
    </section>
</template>

<script>
import {assetsWarehousingTableColumn} from "@/views/property-management/assets-management/data";
import {getParentListPage,assetsTwoDelete,batchGetApi} from "@/api/assets";
import {getStockListAll} from '@/api/warehouse';
import {MessageInfo, MessageSuccess} from "@custom/message";
import {downloadByData} from "@/utils/hooks/download";

export default {
    name: "assetsWarehousing",
    components: {
        dialogAddWarehousing: () => import("./components/dialog-add-warehousing"),
        layerAssetsImport: () => import("./components/layer-assets-import"),
        layerEditAssetsWarehousing: () => import("./components/layer-edit-assets-warehousing")
    },
    data() {
        return {
            tableColumn: assetsWarehousingTableColumn,
            formSearch: {
                assetsName: "",
                assetsState: 0,
            },
            assetsStateList: [],
            lookDialogVisible: false,
            dialogImageUrl: '',
            stockList: [],
            imgList: [],
            dialogVisible: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    methods: {
        getParentListPage(params) {
            return getParentListPage(params);
        },

        handleSearch() {
            this.$refs["rentTableRef"].getTableData(true);
        },

        handleReset() {
            this.formSearch.assetsName = "";
            this.formSearch.assetsState = 0;
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },

        handleContractExport() {

        },

        openAdd() {
            this.$refs["dialogAddWarehousing"].openDialog();
        },

        lookDetail(data) {
            const {uuid: assetsUuid} = data;
            this.$router.push({name: 'assets-details', query: {assetsUuid}});
        },

        handleImport() {
            this.$refs['layerAssetsImport'].openDialog();
        },

        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (index === 6 && !values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);

                } else sums[index] = '';
            });
            return sums;
        },

        goBack() {
            this.$router.back();
        },

        editData(data){
            this.$refs["layerEditAssetsWarehousing"].openDialog(data);
        },

        reomveAsset(data){
            const {uuid} = data;
            let that = this;
            this.$confirm('此操作将删除当前行及所包含的所有资产, 是否继续?', '注意', { type: 'warning' }).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                assetsTwoDelete({uuid}).then(res => {
                    MessageSuccess('删除成功');
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {});
        },
      batchGetApiClick(){
          batchGetApi({code:'800201'}).then(res => {
            console.log(res)
            // window.open(res.info.dataValue,'_blank');
            window.location.assign(res.info.dataValue)
            // downloadByData({ data: res, filename: '导入模板.xlsx' })
              // MessageSuccess('下载成功');
          })
      }
    },
    async mounted() {
        const {assetType} = this.$route.query;
        this.formSearch.assetType = assetType;
        // console.log(assetsUuid);
        this.handleSearch();
        let stockListInfo = await getStockListAll();
        this.stockList = stockListInfo.list;
        this.assetsStateList = await this.$store.dispatch('app/getDictionaryByCodeActions', 700100);
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.assetsWarehousing {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(10px);
        padding: VH(15px) VW(15px);
        justify-content: center;

        .el-radio-button.is-active {
            box-shadow: 0 0 10px #DDD inset;
        }
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }

    .title {
        height: VH(50px);
        line-height: VH(50px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}
</style>